import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const sendRGAEvent = (label, action) => {
	const gtagId = cookies.get('GTAGID');
	const actionSnakeCase = action.toLowerCase().replace(' ', '_');
	const data = {
		event: actionSnakeCase, // Adhere to snake_case convention
		category: 'account',
		action: action,
		label: label,
		nonInteraction: true
	};
	if (gtagId) data.userId = gtagId;
	window.dataLayer.push(data);
};

/**
 * @fileoverview This file contains the code for the UserPlaner component.
 * It includes functionality for managing user plans, API key, and credits.
 *
 * The UserPlaner component includes the following features:
 * - Displaying the user's active plan name
 * - Displaying the user's API key and providing options to copy and refresh it
 * - Displaying the user's compute profiles
 * - Displaying the user's credits and providing an option to buy more
 * - Handling user interactions such as copying API key, refreshing key, and buying credits
 *
 * @copyright (C) 2024 qBraid Development Team
 */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	Box,
	Button,
	Divider,
	IconButton,
	Skeleton,
	Stack,
	TextField,
	Typography,
	useTheme
} from '@mui/material';
import { Cached, ContentCopy } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { createCheckoutSession } from '../../../../Redux/Action/billingAction';
import './index.css';
import CreditModal from '../../../../components/creditModal';
import { RefreshAPIToken } from '../../../../Redux/Action/userAction';

function UserPlaner({ snackbar }) {
	const muiTheme = useTheme();
	const [openCredit, setOpenCredit] = useState(false);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const stripeSelector = useSelector((state) => state.billingReducer);
	const { loading, apiKey } = useSelector((state) => state.userReducer);

	const handleBuyNow = (priceId, mode) => {
		handleCloseCredit();
		snackbar({
			msg: 'Redirecting to payment page...',
			color: false,
			open: true
		});
		dispatch(createCheckoutSession(priceId, mode));
	};

	useEffect(() => {
		if (stripeSelector.showMessage && stripeSelector.responseData?.success) {
			window.open(stripeSelector.responseData?.data?.url, '_self');
		}
		if (
			stripeSelector.showMessage &&
			stripeSelector.responseData?.success === 0
		) {
			Swal.fire({
				icon: 'error',
				text: stripeSelector.responseData?.message
			});
		}
	}, [stripeSelector.showMessage]);

	const handleCloseCredit = () => {
		setOpenCredit(false);
	};
	const handleOpenCredit = () => {
		setOpenCredit(true);
	};

	const handleCopyApiKey = () => {
		navigator.clipboard.writeText(apiKey);
		snackbar({ msg: 'Access key has been copied!', color: false, open: true });
	};

	const handleRefreshKey = () => {
		Swal.fire({
			title: 'Regenrate new API key',
			text: 'Refreshing key will regenerate a new qBraid API key, rendering the previous API key invalid!',
			showCancelButton: true,
			confirmButtonText: 'Refresh',
			confirmButtonColor: muiTheme.palette.primary.main,
			background: muiTheme.palette.background.paper,
			color: muiTheme.palette.text.primary
		}).then((response) => {
			if (response.isConfirmed) {
				dispatch(RefreshAPIToken());
			}
		});
	};

	return (
		<Stack
			paddingX={2.5}
			paddingY={2}
			className="usrPlnr-container"
			gap={2}
			cy-data="dashboard-card"
		>
			<Stack
				flexDirection="row"
				justifyContent="space-between"
				alignItems="center"
				className="usrPlnr-flexBox"
				paddingBottom={1}
			>
				<Stack width={236}>
					<Typography
						fontWeight={900}
						fontSize={20}
						className="nunito-sans primary-clr"
					>
						<Stack
							component="span"
							flexDirection="row"
							alignItems="center"
							gap={1}
						>
							<span>Plan: </span>
							{stripeSelector?.billing?.activePlanName || (
								<Skeleton width={75} />
							)}
						</Stack>
					</Typography>
					<Typography fontWeight={400} fontSize={11} color="text.secondary">
						Manage your compute instances and credits. Copy your API key to
						access qBraid locally.
					</Typography>
				</Stack>
				<Button
					title="Manage your subscriptions"
					variant="outlined"
					size="small"
					sx={{
						fontFamily: 'Nunito sans',
						borderRadius: '100vw',
						fontWeight: 900,
						fontSize: 13
					}}
					onClick={() => navigate('/account-details')}
					cy-data="dashboard-manage-btn"
				>
					Manage
				</Button>
			</Stack>
			<Stack
				flexDirection="row"
				alignItems="center"
				justifyContent="space-between"
			>
				<Stack
					flexDirection="row"
					gap={1}
					justifyContent="space-between"
					alignItems="center"
					flex={1}
					minHeight={40}
				>
					<Typography
						color="text.primary"
						fontSize={14}
						fontFamily="Inter"
						fontWeight={600}
						sx={{ textWrap: 'balance' }}
					>
						Your qBraid API Key:
					</Typography>
					{!apiKey || loading ? (
						<Skeleton width={150} height={30} />
					) : (
						<TextField
							id="standard-basic"
							variant="standard"
							size="small"
							value={apiKey}
							type="password"
							sx={{ maxWidth: 180 }}
							InputProps={{
								readOnly: true,
								disableUnderline: true,
								endAdornment: (
									<Stack
										flexDirection="row"
										alignItems="center"
										justifyContent="center"
										gap={1}
									>
										<IconButton
											size="small"
											title="Copy"
											onClick={handleCopyApiKey}
										>
											<ContentCopy fontSize="inherit" />
										</IconButton>
										<IconButton
											size="small"
											title="Refresh"
											onClick={handleRefreshKey}
										>
											<Cached fontSize="inherit" />
										</IconButton>
									</Stack>
								)
							}}
						/>
					)}
				</Stack>
			</Stack>
			<Divider />
			<Stack gap={1} minHeight={128}>
				<Stack
					flexDirection="row"
					justifyContent="space-between"
					paddingBottom={1}
					gap={1}
				>
					<Box>
						<Typography fontWeight={900} fontSize={18} fontFamily="Nunito sans">
							Credits
						</Typography>
						<Typography
							fontWeight={400}
							fontSize={11}
							color="text.secondary"
							maxWidth="75%"
						>
							Purchasing credits unlocks the qBraid Quantum Jobs extension and
							other features.
						</Typography>
					</Box>
					<Typography fontWeight={600} fontSize={24} fontFamily="Nunito sans">
						{Math.round(stripeSelector?.qbraidCredits) || <Skeleton />}
					</Typography>
				</Stack>
				<Box display="flex" justifyContent="flex-end">
					<Button
						title="Buy more credits"
						variant="outlined"
						size="small"
						sx={{
							fontFamily: 'Nunito sans',
							borderRadius: '100vw',
							fontWeight: 900,
							fontSize: 13
						}}
						onClick={handleOpenCredit}
					>
						Buy Credits
					</Button>
				</Box>
			</Stack>
			<CreditModal
				handleCloseCredit={handleCloseCredit}
				openCredit={openCredit}
				handleBuyNow={handleBuyNow}
			/>
		</Stack>
	);
}

export default UserPlaner;

import { MUIComponents } from './component';
import { darkThemePalette, lightThemePalette } from './palette';
import { MUITypography } from './typography';

export const qusteamLightTheme = {
	palette: lightThemePalette,
	typography: MUITypography,
	components: MUIComponents
};
export const qusteamDarkTheme = {
	palette: darkThemePalette,
	typography: MUITypography,
	components: MUIComponents
};

import React from 'react';
import {
	Button,
	CircularProgress,
	Divider,
	Grid,
	IconButton,
	Typography
} from '@mui/material';
import { Close } from '@mui/icons-material';

function MinimizedForm({
	handleManagePreferences,
	acceptAll,
	rejectAll,
	loading,
	closeHandler
}) {
	return (
		<>
			<Grid
				item
				container
				xs={12}
				justifyContent="space-between"
				alignItems="center"
			>
				<Grid item>
					<Typography
						variant="h6"
						fontWeight={600}
						color="text.primary"
						fontSize={18}
					>
						We use cookies!
					</Typography>
				</Grid>
				<Grid item>
					<IconButton onClick={closeHandler} cy-data="close-cookies-button">
						<Close fontSize="small" />
					</IconButton>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<Typography variant="body1" color="text.secondary" fontSize={15}>
					Our website uses cookies to ensure its proper operation and tracking
					cookies to understand how you interact with it. Tracking will be
					enabled only upon your explicit consent.
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<Divider />
			</Grid>
			{loading ? (
				<Grid
					item
					container
					spacing={2}
					justifyContent="center"
					alignItems="center"
				>
					<Grid item>
						<CircularProgress size={24} />
					</Grid>
					<Grid item>
						<Typography variant="body1" color="text.secondary" fontSize={15}>
							Confirming preferences...
						</Typography>
					</Grid>
				</Grid>
			) : (
				<Grid item container spacing={1}>
					<Grid item xs={12} sm={6}>
						<Button
							variant="contained"
							size="small"
							fullWidth
							sx={{ borderRadius: '100vw', fontSize: 14 }}
							onClick={acceptAll}
						>
							Accept all
						</Button>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Button
							variant="outlined"
							size="small"
							fullWidth
							sx={{ borderRadius: '100vw', fontSize: 14 }}
							onClick={rejectAll}
						>
							Reject all
						</Button>
					</Grid>
					<Grid item xs={12}>
						<Button
							variant="outlined"
							size="small"
							fullWidth
							sx={{ borderRadius: '100vw', fontSize: 14 }}
							onClick={handleManagePreferences}
						>
							Manage preference
						</Button>
					</Grid>
				</Grid>
			)}
		</>
	);
}

export default MinimizedForm;

import {
	USER_LOGIN_LOADING,
	USER_LOGIN_SUCCESS,
	USER_LOGIN_FAIL,
	USER_LOGOUT_LOADING,
	USER_LOGOUT_SUCCESS,
	USER_LOGOUT_FAIL,
	USER_SIGNUP_LOADING,
	USER_SIGNUP_SUCCESS,
	USER_SIGNUP_FAIL,
	USER_DATA_LOADING,
	USER_DATA_SUCCESS,
	USER_DATA_FAIL,
	USER_SUBMIT_NEW_PASSWORD_FAIL,
	USER_SUBMIT_NEW_PASSWORD_LOADING,
	USER_SUBMIT_NEW_PASSWORD_SUCCESS,
	USER_EDIT_PROFILE_LOADING,
	USER_EDIT_PROFILE_SUCCESS,
	USER_EDIT_PROFILE_FAIL,
	MARKETING_UPDATE_FAIL,
	MARKETING_UPDATE_LOADING,
	MARKETING_UPDATE_SUCCESS,
	REFRESH_API_SUCCESS,
	REFRESH_API_FAILURE,
	REFRESH_API_LOADING,
	USER_COGNITO_STORE,
	USER_COGNITO_REMOVE
} from '../Types/userTypes';

const initialState = {
	userData: {},
	userDetails: {},
	apiKey: '',
	success: true,
	loading: false,
	marketing: false,
	message: '', // for snackbar
	userToken: null // for partner login
};
export default function reducer(state = initialState, action) {
	switch (action.type) {
		case USER_SIGNUP_LOADING:
			return {
				...state,
				loading: true
			};
		case USER_SIGNUP_SUCCESS:
			return {
				...state,
				success: true,
				loading: false
			};
		case USER_SIGNUP_FAIL:
			return {
				...state,

				success: false,
				loading: false
			};

		///
		case USER_LOGIN_LOADING:
			return {
				...state,
				loading: true
			};
		case USER_LOGIN_SUCCESS:
			return {
				...state,
				userData: action.payload,
				success: true,
				loading: false
			};
		case USER_LOGIN_FAIL:
			return {
				...state,
				userData: {},
				success: false,
				loading: false
			};

		case USER_LOGOUT_SUCCESS:
			return {
				...state,
				userData: {},
				success: true,
				loading: false
			};
		case USER_LOGOUT_FAIL:
			return {
				...state,
				userData: {},
				success: false,
				loading: false
			};
		case USER_LOGOUT_LOADING:
			return {
				...state,
				userData: {},
				loading: true
			};
		///
		case USER_DATA_LOADING:
			return {
				...state
			};
		case USER_DATA_SUCCESS:
			return {
				...state,
				userDetails: action.payload,
				apiKey: action.payload.apiKey,
				marketing: action.payload.metadata.marketing
			};
		case USER_DATA_FAIL:
			return {
				...state,
				loading: false
			};
		///
		case USER_SUBMIT_NEW_PASSWORD_FAIL:
			return {
				...state,
				success: false,
				loading: false
			};
		case USER_SUBMIT_NEW_PASSWORD_LOADING:
			return {
				...state,
				loading: true
			};
		case USER_SUBMIT_NEW_PASSWORD_SUCCESS:
			return {
				...state,
				success: true,
				loading: false
			};
		/// ///
		case USER_EDIT_PROFILE_LOADING:
			return {
				...state,
				loading: true
			};
		case USER_EDIT_PROFILE_SUCCESS:
			return {
				...state,
				success: true,
				loading: false,
				message: action.payload.message
			};
		case USER_EDIT_PROFILE_FAIL:
			return {
				...state,
				success: false,
				loading: false,
				message: action.payload.message
			};
		case MARKETING_UPDATE_FAIL:
			return {
				...state,
				success: false,
				loading: false,
				marketing: action.payload.marketing,
				message: action.payload.message
			};
		case MARKETING_UPDATE_LOADING:
			return {
				...state,
				loading: true
			};
		case MARKETING_UPDATE_SUCCESS:
			return {
				...state,
				marketing: action.payload.marketing,
				success: true,
				loading: false,
				message: action.payload.message
			};
		// Refresh API key reducer
		case REFRESH_API_SUCCESS:
			return {
				...state,
				apiKey: action.payload.apiKey,
				success: true,
				loading: false,
				message: action.payload.message
			};
		case REFRESH_API_FAILURE:
			return {
				...state,
				success: false,
				loading: false,
				message: action.payload.message
			};
		case REFRESH_API_LOADING:
			return {
				...state,
				loading: true
			};
		case USER_COGNITO_STORE:
			return {
				...state,
				userToken: action.payload
			};
		case USER_COGNITO_REMOVE:
			return {
				...state,
				userToken: null
			};
		default:
			return state;
	}
}

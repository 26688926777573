/**
@fileoverviewThis file contains the modal for the slider which allows users to buy credits on the qBraid platform.
@description The CreditModal component includes:
- The modal for buying credits
- The slider for selecting the number of credits to buy
- The continue and cancel buttons
*/

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
	useRef, forwardRef, useState, useEffect 
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ThreeCircles } from 'react-loader-spinner';
import {
	Button, Grid, Modal, Typography, Box 
} from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Slider from 'react-slick';
import './index.css';
import imageLogo from '../../assets/images/qbraid.png';
import { sendRGAEvent } from '../../utils/googleAnalytics';
import { fetchPricesList } from '../../Redux/Action/billingAction';
import { email } from '../../utils/axiosClient';

function CardCredit({ cardData }) {
	return (
		<Grid
			container
			display="flex"
			flexDirection="column"
			justifyContent="center"
			alignItems="center"
			sx={{ width: '100%', heigth: '100%' }}
		>
			<Grid item>
				<Box
					sx={{ backgroundImage: `url(${imageLogo})` }}
					className="credit_logo"
				/>
			</Grid>
			<Grid item>
				<Typography
					fontFamily="Inter"
					fontWeight={700}
					fontSize={24}
					lineHeight={2}
					mt={2}
					color="text.secondary"
				>
					{cardData.unit_amount}&nbsp;Credits
				</Typography>
			</Grid>
			<Grid item>
				<Typography
					fontFamily="Inter"
					fontWeight={500}
					fontSize={21}
					lineHeight={2}
					textAlign="left"
					color="text.primary"
				>
					${parseFloat(cardData.unit_amount / 100).toFixed(2)}
				</Typography>
			</Grid>
		</Grid>
	);
}
// forward ref is used to get the slider index data, ref is passed from parent component.
const SlickSlider = forwardRef(({ data, onSlideChange }, ref) => {
	const settings = {
		arrows: true,
		lazyLoad: true,
		infinite: true,
		centerMode: false,
		speed: 250,
		cssEase: 'ease',
		nextArrow: <ArrowRight />,
		prevArrow: <ArrowLeft />,
		beforeChange: onSlideChange
	};

	return (
		<div className="slick-slider">
			<Slider ref={ref} {...settings}>
				{data?.map((item) => (
					<CardCredit cardData={item} key={item.id} />
				))}
			</Slider>
		</div>
	);
});

function ArrowRight({ className, onClick }) {
	return (
		<div
			className={className}
			onClick={onClick}
			style={{
				backgroundColor: 'var(--brand-color-1)',
				width: '29px',
				height: '29px',
				borderRadius: '50px',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center'
			}}
		>
			<KeyboardArrowRightIcon sx={{ color: 'white' }} />
		</div>
	);
}
function ArrowLeft({ className, onClick }) {
	return (
		<div
			className={className}
			onClick={onClick}
			style={{
				backgroundColor: 'var(--brand-color-1)',
				width: '29px',
				height: '29px',
				borderRadius: '50px',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center'
			}}
		>
			<KeyboardArrowLeftIcon sx={{ color: 'white' }} />
		</div>
	);
}
function CreditModal({ openCredit, handleCloseCredit, handleBuyNow }) {
	const [slideIndex, setSlideIndex] = useState(0);
	const stripeSelector = useSelector((state) => state.billingReducer);
	const dispatch = useDispatch();
	const slideRef = useRef(null);

	useEffect(() => {
		if (email) {
			dispatch(fetchPricesList());
		}
	}, []);

	return (
		<Modal
			open={openCredit}
			onClose={handleCloseCredit}
			sx={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				backgroundColor: 'action.disabled'
			}}
		>
			{stripeSelector?.creditsData?.length === 0 ? (
				<Box
					className="modal_style_no_data"
					sx={{ backgroundColor: 'background.default' }}
				>
					<Typography
						fontFamily="Inter"
						fontWeight={700}
						fontSize={24}
						lineHeight={3}
						mt={2}
						color="text.primary"
					>
						qBraid Credits
					</Typography>
					<Box
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center'
						}}
					>
						<ThreeCircles
							height="60"
							width="60"
							color="var(--brand-color-1)"
							ariaLabel="grid-loading"
							radius="12.5"
							wrapperStyle={{}}
							wrapperClass=""
							visible
						/>
					</Box>
					<Typography
						fontFamily="Inter"
						fontWeight={700}
						fontSize={24}
						lineHeight={3}
						mt={2}
						color="text.secondary"
					>
						Loading....
					</Typography>
				</Box>
			) : (
				<Box
					className="modal_style"
					sx={{ backgroundColor: 'background.default' }}
				>
					<Typography
						fontFamily="Inter"
						fontWeight={700}
						fontSize={24}
						lineHeight={3}
						mt={1}
						color="text.secondary"
					>
						Buy Credits
					</Typography>
					<Box
						sx={{
							width: '70%'
						}}
					>
						<SlickSlider
							data={stripeSelector.creditsData}
							// eslint-disable-next-line no-shadow
							onSlideChange={(_, slideIndex) => setSlideIndex(slideIndex)}
							ref={slideRef}
						/>
					</Box>
					<Grid
						container
						item
						sm={12}
						sx={(theme) => ({
							padding: '1em',
							gap: 1,
							[theme.breakpoints.down('sm')]: {
								display: 'flex',
								flexDirection: 'column-reverse'
							}
						})}
					>
						<Grid item xs={12} sm={12} md={4.5} lg={4.5} xl={4.5}>
							<Button
								variant="outlined"
								fullWidth
								size="large"
								shape="rounded"
								sx={{
									textTransform: 'none'
								}}
								onClick={handleCloseCredit}
							>
								Cancel
							</Button>
						</Grid>
						<Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
							<Button
								variant="contained"
								shape="rounded"
								fullWidth
								size="large"
								sx={{
									textTransform: 'none'
								}}
								onClick={() => {
									sendRGAEvent('Manage Subscription & Credits', 'Button Click');
									handleBuyNow(
										stripeSelector?.creditsData[slideIndex]?.id,
										'payment'
									);
								}}
							>
								Continue
							</Button>
						</Grid>
					</Grid>
				</Box>
			)}
		</Modal>
	);
}
export default CreditModal;

import React from 'react';
import '@justinribeiro/lite-youtube';
import './index.css';

function VideoPlayer() {
	return (
		<div className="facade-container">
			<lite-youtube
				videoid="iqYmB7H2R2E"
				videoTitle="Using TensorFlow Quantum on qBraid"
			/>
			{/* <lite-youtube
				videoid="K4vb2fzmSZQ"
				videoTitle="Using access codes to connect to quantum devices on QuSTEAM"
			/> */}
		</div>
	);
}

export default VideoPlayer;

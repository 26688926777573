// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (width < 1410px) {
	.span-md {
		grid-column: span 2;
		padding-bottom: 2em;
	}
}
@media (width < 40em) {
	.span-md {
		display: none;
	}
}

.center_logo {
	width: 120% !important;
	display: flex;
}
`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/index.css"],"names":[],"mappings":"AAAA;CACC;EACC,mBAAmB;EACnB,mBAAmB;CACpB;AACD;AACA;CACC;EACC,aAAa;CACd;AACD;;AAEA;CACC,sBAAsB;CACtB,aAAa;AACd","sourcesContent":["@media (width < 1410px) {\n\t.span-md {\n\t\tgrid-column: span 2;\n\t\tpadding-bottom: 2em;\n\t}\n}\n@media (width < 40em) {\n\t.span-md {\n\t\tdisplay: none;\n\t}\n}\n\n.center_logo {\n\twidth: 120% !important;\n\tdisplay: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

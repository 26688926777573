export const NEWS_DATA_LOADING = 'NEWS_DATA_LOADING';
export const NEWS_DATA_SUCCESS = 'NEWS_DATA_SUCCESS';
export const NEWS_DATA_FAIL = 'NEWS_DATA_FAIL';
export const NEWS_LIKE_BYID_LOADING = 'NEWS_LIKE_BYID_LOADING';
export const NEWS_LIKE_BYID_SUCCESS = 'NEWS_LIKE_BYID_SUCCESS';
export const NEWS_LIKE_BYID_FAIL = 'NEWS_LIKE_BYID_FAIL';
export const NEWS_BOOKMARK_BYID_LOADING = 'NEWS_BOOKMARK_BYID_LOADING';
export const NEWS_BOOKMARK_BYID_SUCCESS = 'NEWS_BOOKMARK_BYID_SUCCESS';
export const NEWS_BOOKMARK_BYID_FAIL = 'NEWS_BOOKMARK_BYID_FAIL';
export const NEWS_SUBMIT_LOADING = 'NEWS_SUBMIT_LOADING';
export const NEWS_SUBMIT_SUCCESS = 'NEWS_SUBMIT_SUCCESS';
export const NEWS_SUBMIT_FAIL = 'NEWS_SUBMIT_FAIL';
export const NEWS_DELETE_LOADING = 'NEWS_DELETE_LOADING';
export const NEWS_DELETE_SUCCESS = 'NEWS_DELETE_SUCCESS';
export const NEWS_DELETE_FAIL = 'NEWS_DELETE_FAIL';

export const EVENTS_DATA_LOADING = 'EVENTS_DATA_LOADING';
export const EVENTS_DATA_SUCCESS = 'EVENTS_DATA_SUCCESS';
export const EVENTS_DATA_FAIL = 'EVENTS_DATA_FAIL';
export const EVENTS_LIKE_BYID_LOADING = 'EVENTS_LIKE_BYID_LOADING';
export const EVENTS_LIKE_BYID_SUCCESS = 'EVENTS_LIKE_BYID_SUCCESS';
export const EVENTS_LIKE_BYID_FAIL = 'EVENTS_LIKE_BYID_FAIL';
export const EVENTS_BOOKMARK_BYID_LOADING = 'EVENTS_BOOKMARK_BYID_LOADING';
export const EVENTS_BOOKMARK_BYID_SUCCESS = 'EVENTS_BOOKMARK_BYID_SUCCESS';
export const EVENTS_BOOKMARK_BYID_FAIL = 'EVENTS_BOOKMARK_BYID_FAIL';
export const EVENT_SUBMIT_LOADING = 'EVENT_SUBMIT_LOADING';
export const EVENT_SUBMIT_SUCCESS = 'EVENT_SUBMIT_SUCCESS';
export const EVENT_SUBMIT_FAIL = 'EVENT_SUBMIT_FAIL';
export const EVENT_DELETE_LOADING = 'EVENT_DELETE_LOADING';
export const EVENT_DELETE_SUCCESS = 'EVENT_DELETE_SUCCESS';
export const EVENT_DELETE_FAIL = 'EVENT_DELETE_FAIL';

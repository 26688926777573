/**
 * Renders the footer component.
 *
 * @component
 * @example
 * return (
 *   <Footer />
 * )
 * @copyright (C) 2024 qBraid Development Team
 */

import { forwardRef } from 'react';
import {
	Grid, Typography, Container, IconButton, Box 
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { GitHub, LinkedIn } from '@mui/icons-material';
import XIcon from '@mui/icons-material/X';
import { Link } from 'react-router-dom';
import discord from '../../assets/images/discord.svg';
import { sendRGAEvent } from '../../utils/googleAnalytics';
// eslint-disable-next-line import/named
import {
	DISCORD_URL, DOCS_URL, FOOTER_WORK_WITH_US_URL, GIT_HUB_URL, LINKED_IN_URL, X_URL 
} from '../../utils/axiosClient';
import './index.css';
import { currentDomain } from '../../utils/domainBasedInfo/currentDomain';

function FooterComponent(props, ref) {
	const theme = useTheme();
	const handleRedirection=(url)=>{
		if(!url){
			return
		}
		window.open(
			url,
			'_blank' 
		  );
	}
	return (
		<footer
			style={{
				position: 'fixed',
				zIndex: 10,
				bottom: 0,
				width: '100%'
			}}
			id="footer"
			ref={ref}
		>
			<Container
				maxWidth={false}
				sx={{ backgroundColor: theme.palette.background.default }}
			>
				<Grid container p={1}>
					<Grid item container xs={12} md={4} lg={3} justifyContent="center">
						<Box display="flex" alignItems="center" gap={1}>
							<img
								src={currentDomain.logo_simple.logo}
								alt="logo"
								height={18}
								width={18}
							/>
							<Typography fontSize={14} color="text.secondary">
								© 2024 qBraid Co.
							</Typography>
						</Box>
					</Grid>

					<Grid
						item
						container
						xs={12}
						md={8}
						lg={7}
						gap={2}
						justifyContent={{ xs: 'center', md: 'flex-end', lg: 'center' }}
					>
						<Grid item>
							<Link to="/" className="footer_link">
								Home
							</Link>
						</Grid>
						<Grid item>
							<a
								onClick={() => {
									sendRGAEvent('Docs', 'Button Click');
								}}
								href={DOCS_URL}
								className="footer_link"
								target="_blank"
								rel="noopener noreferrer"
							>
								Docs
							</a>
						</Grid>
						<Grid item>
							<a
								onClick={() => {
									sendRGAEvent('Careers', 'Button Click');
								}}
								href={FOOTER_WORK_WITH_US_URL}
								className="footer_link"
								target="_blank"
								rel="noopener noreferrer"
							>
								Careers
							</a>
						</Grid>
						<Grid item>
							<Link to="/terms-and-conditions" className="footer_link">
								Terms &amp; Conditions
							</Link>
						</Grid>
						<Grid item>
							<Link to="/cookie-policy" className="footer_link">
								Cookie Policy
							</Link>
						</Grid>
						<Grid item>
							<Link to="/privacy-policy" className="footer_link">
								Privacy Policy
							</Link>
						</Grid>
					</Grid>

					<Grid
						item
						container
						xs={12}
						md={12}
						lg={2}
						justifyContent={{ xs: 'center', md: 'flex-end' }}
					>
						<Box display="flex" gap={1}>
							<IconButton size="small" onClick={()=>handleRedirection(X_URL)}>
								<XIcon fontSize="inherit" />
							</IconButton>

							<IconButton size="small" onClick={()=>handleRedirection(DISCORD_URL)}>
								<img
									src={discord}
									alt="icons"
									width={22}
									height={22}
									style={{ objectFit: 'contain', opacity: 0.7,filter:theme.palette.mode==='dark'?'invert(1)':'invert(0)' }}
								/>
							</IconButton>

							<IconButton size="small" onClick={()=>handleRedirection(LINKED_IN_URL)}>
								<LinkedIn fontSize="medium" />
							</IconButton>

							<IconButton size="small" onClick={()=>handleRedirection(GIT_HUB_URL)}>
								<GitHub fontSize="medium" />
							</IconButton>
						</Box>
					</Grid>
				</Grid>
			</Container>
		</footer>
	);
}

export const Footer = forwardRef(FooterComponent);

import React, {
	useCallback, useEffect, useMemo, useState 
} from 'react';
import { useSelector } from 'react-redux';
import { Card, Grid } from '@mui/material';
import Cookies from 'universal-cookie';
import EnlargedForm from './EnlargedForm';
import MinimizedForm from './MinimizedForm';
import { currentDomain } from '../../utils/domainBasedInfo/currentDomain';
import { updateCookieConsent } from '../../Redux/Action/userAction';

const CONSENTCOOKIE = 'consent-cookie';

const INITIALSTATE = currentDomain.cookies_consent.reduce((acc, cookie) => {
	acc[cookie.id] = cookie.default_value;
	return acc;
}, {});

const isLocalHost =
	(window.location.href.match('localhost:300') || []).length === 1;
const domain = !isLocalHost ? currentDomain.domain : 'localhost';

/**
 * CookieConsent component for displaying a cookie consent message.
 *
 * @param {Object} props - The component props.
 * @param {string} props.positionX - The horizontal position of the cookie consent component (e.g., 'left', 'right').
 * @param {string} props.positionY - The vertical position of the cookie consent component (e.g., 'top', 'bottom').
 * @param {boolean} props.open - [Optional] Force open the cookie consent component visible (true) if called from parent component upon event.
 * @param {Function} [props.close=() => {}] - [Optional] The function to call to close or hide the cookie consent component. Defaults to an empty function.
 *
 * @returns {JSX.Element} The rendered CookieConsent component.
 */
function CookieConsent({
	positionX = 'left',
	positionY = 'bottom',
	open,
	close = () => {}
}) {
	const [decisionMade, setDecisionMade] = useState(true); // start with true to avoid flashing
	const [managePreference, setManagePreference] = useState(false);
	const [preferedCookies, setPreferedCookies] = useState(INITIALSTATE);
	const [loading, setLoading] = useState(false);
	const { userDetails } = useSelector((state) => state.userReducer);
	const { metadata } = userDetails;
	const cookies = useMemo(() => new Cookies(), []);

	useEffect(() => {
		if (metadata) {
			const cookieConsentGiven = metadata?.cookieConsentGiven || false;
			let cookieObject = {};
			if (cookieConsentGiven) {
				cookieObject = metadata?.cookieConsentObject || {};
			}

			if (cookies.get(CONSENTCOOKIE) !== undefined && cookieConsentGiven) {
				// if CONSENTCOOKIE is PRESENT & consent from api is TRUE, then we dont need to pop-put cookie card
				setDecisionMade(true);
				setPreferedCookies(cookieObject);
				if (open) {
					close();
				}
			} else if (
				cookies.get(CONSENTCOOKIE) !== undefined &&
				!cookieConsentGiven
			) {
				// if CONSENTCOOKIE is PRESENT & consent from api is FALSE, then we need to pop-put cookie card
				cookies.remove(CONSENTCOOKIE);
				setDecisionMade(false);
			} else if (!cookies.get(CONSENTCOOKIE) && cookieConsentGiven) {
				// if CONSENTCOOKIE is NOT PRESENT & consent from api is TRUE, then we dont need need to pop-put cookie card
				// rather, update the current CONSENTCOOKIE
				setDecisionMade(true);
				if (open) {
					close();
				}
				setPreferedCookies(cookieObject);
				// set cookieObject with values from api
				handleCookie(cookieObject);
			} else {
				setDecisionMade(false);
			}
		}
	}, [metadata]);

	useEffect(() => {
		if (open) {
			setDecisionMade(false);
		}
	}, [open]);

	const gtag = () => {
		// Send Google Analytics event tracking for cookie consent dialog
		window.dataLayer = window.dataLayer || [];
		// eslint-disable-next-line
		window.dataLayer.push(arguments);
	};

	const sendConsent = useCallback((consent) => {
		gtag('consent', 'default', consent);
	}, []);

	const togglePreference = () => setManagePreference((prev) => !prev);

	const handleCookie = (consent) => {
		cookies.set(CONSENTCOOKIE, consent, {
			expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
			path: '/',
			domain: domain
		});
		sendConsent(consent);
		setDecisionMade(true);
		if (open) {
			close();
		}
	};

	const grantAllConsents = (consentStatus) =>
		Object.keys(consentStatus).reduce((acc, key) => {
			acc[key] = 'granted';
			return acc;
		}, {});

	const handleAcceptAll = async () => {
		setLoading(true);
		const consent = grantAllConsents(INITIALSTATE);
		updateCookieConsent(consent).then((res) => {
			if (res === 'success') {
				handleCookie(consent);
			}
			setLoading(false);
		});
	};

	const handleRejectAll = async () => {
		setLoading(true);
		const consent = INITIALSTATE;
		updateCookieConsent(consent).then((res) => {
			if (res === 'success') {
				handleCookie(consent);
			}
			setLoading(false);
		});
	};

	const handleSavePreference = async () => {
		setLoading(true);
		let consent = {};
		Object.entries(preferedCookies).forEach((preference) => {
			consent = { ...consent, [preference[0]]: preference[1] };
		});
		updateCookieConsent(consent).then((res) => {
			if (res === 'success') {
				handleCookie(consent);
			}
			setLoading(false);
		});
	};

	const handleChoosePreference = (option) =>
		setPreferedCookies((prev) => ({
			...prev,
			[option]: prev[option] === 'denied' ? 'granted' : 'denied'
		}));

	const closeHandler = () => {
		if (open) {
			close();
		}
		setDecisionMade(true);
	};

	return decisionMade ? null : (
		<Card
			sx={{
				position: 'fixed',
				borderRadius: 2,
				padding: 3,
				bottom: positionY === 'bottom' ? 58 : 'auto',
				top: positionY === 'top' ? 18 : 'auto',
				left: positionX === 'left' ? 18 : 'auto',
				right: positionX === 'right' ? 18 : 'auto',
				zIndex: 110
			}}
			elevation={6}
			cy-data='consent-cookie-card'
		>
			<Grid
				container
				spacing={2}
				maxWidth={managePreference ? 500 : 350}
				sx={{ transition: 'width 500ms linear' }}
			>
				{managePreference ? (
					<EnlargedForm
						cookieTypes={currentDomain.cookies_consent}
						handleManagePreferences={togglePreference}
						acceptAll={handleAcceptAll}
						rejectAll={handleRejectAll}
						preferedCookies={preferedCookies}
						choosePreference={handleChoosePreference}
						savePreference={handleSavePreference}
						loading={loading}
					/>
				) : (
					<MinimizedForm
						handleManagePreferences={togglePreference}
						acceptAll={handleAcceptAll}
						rejectAll={handleRejectAll}
						loading={loading}
						closeHandler={closeHandler}
					/>
				)}
			</Grid>
		</Card>
	);
}

export default CookieConsent;

/**
 * @fileoverview This file contains the code for the Dashboard component.
 * It includes functionality for displaying the main dashboard page.
 *
 * The Dashboard component includes the following features:
 * - Displaying a GitHub integration component to clone repositories
 * - Displaying a user planner component
 * - Displaying a video player or an image based on the current domain settings
 * - Displaying a description component with header, subtext, and a link
 * - Handling snackbar messages and closing snackbar
 * - Displaying a billing callback component
 *
 * @copyright (C) 2024 qBraid Development Team
 */

import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import BillingCallback from '../billing-callback';
import CustomCard from '../../components/card';
import DescriptionComponent from '../../components/descriptionComponent';
import GitHubIntegration from '../../components/integrations/github';
import Snackbar from '../../components/snackbar/index';
import UserPlaner from './components/userplaner';
import useTitle from '../../hooks/useTitle';
import VideoPlayer from './components/videoplayer';
import { email } from '../../utils/axiosClient';
import { sendRGAEvent } from '../../utils/googleAnalytics';
import { currentDomain } from '../../utils/domainBasedInfo/currentDomain';
import './index.css';
import ComputeInstance from './components/computeInstance';

/**
 * Dashboard component shows the user plan, a video, and description of how to use qBraid.
 * @function The Dashboard component is the main page where users typically land on.
 * @returns {JSX.Element}
 */
function Dashboard() {
	useTitle(`Dashboard | ${currentDomain.title}`);
	const [openSnack, setOpenSnack] = useState(false);
	const [snackMessage, setSnackMessage] = useState();
	const [snackColor, setSnackColor] = useState(false);

	const handleCloseSnack = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpenSnack(false);
	};

	useEffect(() => {
		sendRGAEvent('Dashboard', 'Page View');
	}, []);

	useEffect(() => {
		const isGoogle = localStorage.getItem('isGoogle');
		const errorTimer = setTimeout(() => {
			if (isGoogle && !email) {
				setSnackColor(true);
				setOpenSnack(true);
				setSnackMessage(
					"You didn't use google sign in. Please try logging in with email and password."
				);
				localStorage.removeItem('isGoogle');
			}
		}, 20);

		return () => {
			clearTimeout(errorTimer);
		};
	}, []);

	const handleSnackbar = ({ msg, open, color }) => {
		setSnackMessage(msg);
		setSnackColor(color);
		setOpenSnack(open);
	};

	return (
		<Grid container spacing={2}>
			<GitHubIntegration />
			<Grid item xs={12} sm={6} md={4}>
				<CustomCard
					variant="solid"
					isHoverable={false}
					animate="slide-in-bottom"
				>
					<UserPlaner snackbar={handleSnackbar} />
				</CustomCard>
			</Grid>
			<Grid item xs={12} sm={6} md={4}>
				<CustomCard
					variant="solid"
					isHoverable={false}
					animate="slide-in-bottom"
					customStyles={{
						height: '100%',
						display: 'flex',
						flexDirection: 'column'
					}}
				>
					<ComputeInstance />
				</CustomCard>
			</Grid>
			<Grid item container xs={12} md={4}>
				<CustomCard
					variant="transparent"
					bgColor="transparent"
					animate="slide-in-bottom"
					customStyles={{
						animationDelay: '0.25s',
						opacity: '0',
						flex: 1
					}}
					cyData="media-card"
				>
					{currentDomain.dashboard.video_id ? (
						<VideoPlayer
							videoId={currentDomain.dashboard.video_id}
							videoTitle={currentDomain.dashboard.video_title}
						/>
					) : (
						<img
							src={currentDomain.dashboard.image}
							alt={currentDomain.title}
							className="center_logo"
							cy-data="image-card"
						/>
					)}
					{/*  */}
				</CustomCard>
			</Grid>

			<Grid item xs={12} mt={2} display={{ xs: 'none', md: 'block' }}>
				<CustomCard
					variant="transparent"
					bgColor="transparent"
					isHoverable={false}
					animate="slide-in-right"
					customCSS="span-md"
				>
					<DescriptionComponent
						headerText={currentDomain.dashboard.title}
						subText={currentDomain.dashboard.description}
						link={{
							linkName: currentDomain.dashboard.button,
							url: currentDomain.dashboard.button_link
						}}
					/>
				</CustomCard>
			</Grid>
			<Snackbar
				openSnack={openSnack}
				handleCloseSnack={handleCloseSnack}
				snackMessage={snackMessage}
				snackColor={snackColor}
			/>
			<BillingCallback />
		</Grid>
	);
}

export default Dashboard;

import {
	EVENTS_DATA_LOADING,
	EVENTS_DATA_SUCCESS,
	EVENTS_DATA_FAIL,
	EVENTS_LIKE_BYID_LOADING,
	EVENTS_LIKE_BYID_SUCCESS,
	EVENTS_LIKE_BYID_FAIL,
	EVENTS_BOOKMARK_BYID_LOADING,
	EVENTS_BOOKMARK_BYID_SUCCESS,
	EVENTS_BOOKMARK_BYID_FAIL,
	EVENT_SUBMIT_LOADING,
	EVENT_SUBMIT_SUCCESS,
	EVENT_SUBMIT_FAIL,
	EVENT_DELETE_LOADING,
	EVENT_DELETE_SUCCESS,
	EVENT_DELETE_FAIL
} from '../Types/eventsTypes';

const initialState = {
	isEventsloading: true,
	eventsArray: [],
	bookmarkEvents: [],
	success: true,
	message: ''
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case EVENTS_BOOKMARK_BYID_LOADING:
			return {
				...state
			};
		case EVENTS_BOOKMARK_BYID_SUCCESS: {
			const i = state.eventsArray.eventsArray.findIndex(
				(x) => x._id === action.id
			);
			state.eventsArray.eventsArray[i].cardInfo.bookmarkedBy =
				action.payload.usersBookMarkedEvents;

			return {
				...state,
				bookmarkEvents: action.payload,
				eventsArray: state.eventsArray,
				success: true
			};
		}
		case EVENTS_BOOKMARK_BYID_FAIL:
			return {
				...state,
				success: false
			};
		case EVENTS_LIKE_BYID_LOADING:
			return {
				...state
			};
		case EVENTS_LIKE_BYID_SUCCESS: {
			const index = state.eventsArray.eventsArray.findIndex(
				(x) => x._id === action.id
			);
			state.eventsArray.eventsArray[index].cardInfo.upvotersArray =
				action.payload.upvoters;

			return {
				...state,

				eventsArray: state.eventsArray,
				success: true
			};
		}
		case EVENTS_LIKE_BYID_FAIL:
			return {
				...state,
				success: false
			};
		case EVENTS_DATA_LOADING:
			return {
				...state,
				isEventsloading: true
			};
		case EVENTS_DATA_SUCCESS:
			return {
				...state,
				isEventsLoading: false,
				success: true,
				eventsArray: action.payload
			};
		case EVENTS_DATA_FAIL:
			return {
				...state,
				isEventsloading: false,
				success: false
			};
		case EVENT_SUBMIT_LOADING:
			return {
				...state
			};
		case EVENT_SUBMIT_SUCCESS:
			return {
				...state,
				success: true,
				message: action.payload.message
			};
		case EVENT_SUBMIT_FAIL:
			return {
				...state,
				success: false,
				message: action.payload.message
			};
		case EVENT_DELETE_LOADING:
			return {
				...state
			};
		case EVENT_DELETE_SUCCESS:
			return {
				...state,
				success: true
			};
		case EVENT_DELETE_FAIL:
			return {
				...state,
				success: false
			};
		default:
			return state;
	}
}

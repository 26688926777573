import {
	NEWS_DATA_LOADING,
	NEWS_DATA_SUCCESS,
	NEWS_DATA_FAIL,
	NEWS_LIKE_BYID_LOADING,
	NEWS_LIKE_BYID_SUCCESS,
	NEWS_LIKE_BYID_FAIL,
	NEWS_BOOKMARK_BYID_LOADING,
	NEWS_BOOKMARK_BYID_SUCCESS,
	NEWS_BOOKMARK_BYID_FAIL,
	NEWS_SUBMIT_LOADING,
	NEWS_SUBMIT_SUCCESS,
	NEWS_SUBMIT_FAIL,
	NEWS_DELETE_LOADING,
	NEWS_DELETE_SUCCESS,
	NEWS_DELETE_FAIL
} from '../Types/newsTypes';

const initialState = {
	isNewsLoading: true,
	newsArray: [],
	upvoters: {},
	newsLikesArray: [],
	bookmarkArray: [],
	success: true,
	message: ''
};
export default function reducer(state = initialState, action) {
	switch (action.type) {
		case NEWS_BOOKMARK_BYID_LOADING:
			return {
				...state
			};
		case NEWS_BOOKMARK_BYID_SUCCESS: {
			const i = state.newsArray.newsArray.findIndex((x) => x._id === action.id);

			state.newsArray.newsArray[i].cardInfo.bookmarkedBy =
				action.payload.usersBookMarkedNews;
			return {
				...state,
				newsArray: state.newsArray,
				success: true
			};
		}
		case NEWS_BOOKMARK_BYID_FAIL:
			return {
				...state,
				success: false
			};

		case NEWS_LIKE_BYID_LOADING:
			return {
				...state
			};
		case NEWS_LIKE_BYID_SUCCESS: {
			const index = state.newsArray.newsArray.findIndex(
				(x) => x._id === action.id
			);
			state.newsArray.newsArray[index].cardInfo.upvotersArray =
				action.payload.upvoters;

			return {
				...state,
				newsArray: state.newsArray,
				success: true
			};
		}
		case NEWS_LIKE_BYID_FAIL:
			return {
				...state,
				success: false
			};
		case NEWS_DATA_LOADING:
			return {
				...state,
				isNewsLoading: true
			};
		case NEWS_DATA_SUCCESS:
			return {
				...state,
				isNewsLoading: false,
				newsArray: action.payload,
				success: true
			};
		case NEWS_DATA_FAIL:
			return {
				...state,
				isNewsLoading: false,
				success: false
			};

		case NEWS_SUBMIT_LOADING:
			return {
				...state
			};
		case NEWS_SUBMIT_SUCCESS:
			return {
				...state,
				message: action.payload.message,
				success: true
			};
		case NEWS_SUBMIT_FAIL:
			return {
				...state,

				success: false
			};

		case NEWS_DELETE_LOADING:
			return {
				...state
			};
		case NEWS_DELETE_SUCCESS:
			return {
				...state,
				success: true
			};
		case NEWS_DELETE_FAIL:
			return {
				...state,
				success: false
			};
		default:
			return state;
	}
}
